import { Controller } from '@hotwired/stimulus'
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import {
  faTachometerAlt,
  faFolders,
  faGraduationCap,
  faShieldAlt,
  faAngleDown,
  faPlus,
  faSpinnerThird,
  faEnvelope,
  faPhone,
  faComments,
  faEllipsisHAlt,
  faServer,
  faCog,
  faUsersCrown,
  faGift,
  faFileInvoice,
  faUsers,
  faWrench,
  faCopyright,
  faUserCog,
  faSignOutAlt,
  faTrashAlt,
  faGlobe,
  faCheck,
  faStopwatch,
  faEdit,
  faBoxCheck,
  faGripHorizontal,
  faListUl,
  faTimes,
  faCloudUploadAlt,
  faCloudDownload,
  faFilePdf,
  faCheckCircle as faCheckCircleLight,
  faPencil,
  faSignIn,
  faUserPlus,
  faBug,
  faFileContract,
  faPlay,
  faMinus,
  faArrowUp,
  faArrowDown,
  faShieldCheck,
  faUserMinus,
  faUserEdit,
  faCreditCard,
  faQuestionCircle,
  faBookmark,
  faHandshakeAlt,
  faCopy,
  faShareSquare,
  faCube,
  faUniversity,
  faUsersCog,
  faExternalLink,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTire,
  faInfoCircle,
  faExclamationCircle,
  faFileSearch,
  faAddressCard,
  faIdCardAlt,
  faShareAlt,
  faLifeRing,
  faCoin,
  faBlog,
  faTable,
  faDatabase,
  faBuilding,
  faSync,
  faHandPointer,
  faWind,
  faGears,
  faCarMirrors,
  faDroneFront,
  faMotorcycle,
  faPlane,
  faCity,
  faSolarPanel,
  faArrowRight,
  faPenToSquare,
  faLock,
  faUnlock,
  faCloudArrowDown,
} from '@fortawesome/pro-light-svg-icons'

import {
  faCheckCircle,
  faStop,
  faArrowLeft,
  faPlay as faPlaySolid,
  faCircleInfo,
  faGripDots,
  faArrowProgress,
  faCircleXmark,
  faPlus as fasPlus,
  faMinus as fasMinus,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faFacebook,
  faGoogle,
  faTwitter,
  faYoutube,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons'

config.mutateApproach = 'sync'

library.add([
  faTachometerAlt,
  faFolders,
  faGraduationCap,
  faShieldAlt,
  faAngleDown,
  faPlus,
  faMinus,
  faSpinnerThird,
  faEnvelope,
  faPhone,
  faComments,
  faEllipsisHAlt,
  faServer,
  faCog,
  faUsersCrown,
  faGift,
  faFileInvoice,
  faUsers,
  faWrench,
  faPlay,
  faCopyright,
  faUserCog,
  faSignOutAlt,
  faTrashAlt,
  faGlobe,
  faCheck,
  faStopwatch,
  faEdit,
  faBoxCheck,
  faGripHorizontal,
  faListUl,
  faTimes,
  faCloudUploadAlt,
  faCheckCircle,
  faFilePdf,
  faStop,
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faCheckCircle,
  faCheckCircleLight,
  faCloudDownload,
  faPencil,
  faArrowLeft,
  faSignIn,
  faUserPlus,
  faBug,
  faFileContract,
  faFacebook,
  faGoogle,
  faTwitter,
  faPlay,
  faStop,
  faPlaySolid,
  faArrowUp,
  faArrowDown,
  faShieldCheck,
  faUserMinus,
  faUserEdit,
  faCreditCard,
  faQuestionCircle,
  faBookmark,
  faHandshakeAlt,
  faCopy,
  faShareSquare,
  faYoutube,
  faYoutubeSquare,
  faCube,
  faUniversity,
  faUsersCog,
  faExternalLink,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faTire,
  faInfoCircle,
  faExclamationCircle,
  faFileSearch,
  faAddressCard,
  faIdCardAlt,
  faShareAlt,
  faLifeRing,
  faCoin,
  faBlog,
  faTable,
  faDatabase,
  faBuilding,
  faHandPointer,
  faCube,
  faSync,
  faWind,
  faGears,
  faCarMirrors,
  faDroneFront,
  faMotorcycle,
  faPlane,
  faCity,
  faSolarPanel,
  faArrowRight,
  faPenToSquare,
  faLock,
  faUnlock,
  faCloudArrowDown,
  faCircleInfo,
  faGripDots,
  faArrowProgress,
  faCircleXmark,
  fasPlus,
  fasMinus,
])
dom.watch()

export default class extends Controller {
  initialize() {}
  connect() {
    dom.i2svg()
  }
}
